.order-card, .client-card {
    background-color: #FFFFFF; /* white background for the cards */
    border: 1px solid #D0EBFF; /* blue 1 for a subtle border */
    border-radius: 8px;
    box-shadow: 0 2px 6px rgba(0, 0, 0, 0.1);
    margin-bottom: 1rem;
    overflow: hidden;
    transition: box-shadow 0.2s;
}

.order-card:hover, .client-card:hover {
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.15);
}

.order-card-header, .client-card-header {
    background-color: #E7F5FF; /* blue 0 for headers */
    padding: 0.75rem 1.25rem;
    border-bottom: 1px solid #D0EBFF; /* blue 1 to define the header */
	display: flex;
	justify-content: space-between;
	align-items: flex-end;

}

.client-card-header-actions{
	display: flex;
	justify-content: space-around;
	align-items: flex-end;
	gap:1rem;
}

.order-card-title, .client-card-title {
    color: #228BE6; /* blue 6 for titles */
    margin: 0;
}

.order-card-body, .client-card-body {
    padding: 1.25rem;
}

.order-info {
    display: flex;
    justify-content: space-between;
    margin-bottom: 0.5rem;
}

.order-label {
    color: #495057; /* gray 7 for labels */
}

.order-value, .client-details li {
    color: #343A40; /* gray 8 for values */
}
.order-value-attention, .order-label-attention {
	color: #FF6B6B; /* red 5 for values that need attention */
	font-weight: bold;
}

.client-details li strong {
    color: #0C8599; /* cyan 8 for emphasis */
}

.order-card-footer {
    padding: 0.75rem 1.25rem;
    background-color: #F1F3F5; /* gray 1 for footer */
    border-top: 1px solid #D0EBFF; /* blue 1 at the top of the footer */
}

.order-button {
    background-color: #339AF0; /* blue 5 for buttons */
    color: #FFFFFF; /* white for button text */
    border: none;
    border-radius: 0.25rem;
    padding: 0.375rem 0.75rem;
    cursor: pointer;
    font-size: 0.875rem;
    transition: background-color 0.2s, transform 0.1s;
}

.order-button:hover {
    background-color: #1C7ED6; /* blue 7 for a darker hover state */
    transform: translateY(-2px);
}

.drawer-section-title {
    color: #495057; /* gray 7 for section titles */
    margin-top: 2rem;
}

@keyframes radiate {
    0% {
        box-shadow: 0 0 0 0 rgba(0, 123, 255, 0.7);
    }
    70% {
        box-shadow: 0 0 0 10px rgba(0, 123, 255, 0);
    }
    100% {
        box-shadow: 0 0 0 0 rgba(0, 123, 255, 0);
    }
}

.active-icon-client-detalis {
    animation: radiate 1.5s infinite;
}
