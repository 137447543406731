@media print {
	#Header,
	#Footer {
		display: none !important;
	}
	@page {
		/* margin: 20mm; */
		margin: 0.3in 20mm 0.3in 20mm !important;
		font-size: 12px;
	}
}

.a4-container {
	width: 794px;
	height: 1123px;
	background-color: white;
	margin: 0 auto;
	box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
	overflow: hidden;
	/* padding: 76px 76px 76px 76px; */
	padding: 0.3in 20mm 0.3in 20mm;
}

.pdf-table tr td {
	font-size: 12px;
	padding: 4px 16px;
	color: var(--heading);
}

.pdf-table tr {
	border-bottom: 1px solid var(--orange);
}

.pricing-table {
	color: var(--heading);
	table-layout: fixed;
	width: 100%;
	border-collapse: collapse;
}

.pricing-table td {
	font-size: 12px;
	color: var(--heading);
	border: 1px solid var(--divider);
	padding: 4px 16px;
}

.bold-row td {
	font-size: 14px;
	font-weight: bold;
}

.pricing-table td:last-child {
	text-align: right;
}

.pdf-table tr:last-child {
	border-bottom: none;
}

.plans-table {
	background-color: var(--lightBrown);
	color: var(--heading);
}

.plans-table td {
	font-size: 12px;
	padding: 4px 4px;
	word-wrap: break-word;
	border: 1px solid var(--greenAccent);
}

.plans-table th {
	font-size: 12px;
	padding: 4px 4px;
	word-wrap: break-word;
	border: 1px solid var(--greenAccent);
}
