:root {
	/* --dark2: rgb(15, 34, 45); */

	--accent: #e9a708;
	--accentLight: rgba(233, 167, 8, 0.06);
	--accent80: rgb(233, 167, 8, 0.8);
	--accent20: rgb(233, 167, 8, 0.2);
	--accent10: rgb(233, 167, 8, 0.1);
	--bg: #fafafa;
	--bg10: rgba(250, 250, 250, 0.1);
	--elevation: #ffffff;
	--negative-elevation: #f6f7f9;
	--heading: #0f222d;
	--body: #333333;
	--divider: #dcdfe4;
	--divider40: rgba(220, 223, 228, 0.4);
	--divider20: rgba(220, 223, 228, 0.2);
	--grey: #adb5bd;
	--bodyLight: #667085;
	--error: #f04438;
	--error10: rgba(240, 68, 56, 0.1);
	--success: #15b79f;
	--pending: #06aed4;
	--purple: #7950f2;
	--lightBrown: #eae4d8;
	--orange: #eba278;
	--greenAccent: #738063;
	/* EFFECTS */
	--sh: 0px 0px 0px 0px rgba(0, 0, 0, 0.06), 0px 5px 22px 0px rgba(0, 0, 0, 0.04);
	--sh-accent: 0px 1px 85px 1px var(--accent20);
	--inset-sh: inset 0px 4px 12px -4px rgba(0, 0, 0, 0.25);
}

* {
	margin: 0;
	padding: 0;
	box-sizing: border-box;
}

.button-style {
	font-size: 16px;
	height: 48px;
	border-radius: 8px;
	padding: 0 24px;
}

.sec-button-style {
	font-size: 16px;
	height: 48px;
	border-radius: 8px;
	padding: 0 24px;
}

.input-styles {
	min-height: 48px;
	border-radius: 8px;
	background: var(--elevation);
}

.input-styles::placeholder {
	font-size: 16px;
}

.select-item[data-selected] {
	background-color: var(--accent);
}

.select-item[data-selected]:hover {
	background-color: var(--accent);
}

.select-item-soft[data-selected] {
	background-color: var(--divider40);
}

.select-item-soft[data-selected]:hover {
	background-color: var(--divider40);
}

.text-underline-hover:hover {
	text-decoration: underline;
	color: inherit;
}

.ellipsis-overflow {
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
	/* add the max-width in the styles of the component */
}

.ellipsis-multiline-overflow {
	display: -webkit-box;
	line-clamp: 6;
	-webkit-line-clamp: 6;
	-webkit-box-orient: vertical;
	overflow: hidden;
	text-overflow: ellipsis;
}

.auto-grid {
	width: 100%;
	display: grid;
	grid-template-columns: repeat(auto-fit, minmax(min(16em, 100%), 1fr));
	gap: 32px;
}

.link-style {
	cursor: pointer;
	transition: 1s;
}

.link-style:hover {
	text-decoration: underline;
}

.pac-container {
	z-index: 110000 !important;
}

td {
	font-size: 0.875rem;
}

@media (max-width: 767px) {
	#brevo-conversations:not(.brevo-conversations--expanded) {
		width: 50px !important;
		height: 50px !important;
		margin-bottom: 40px !important;
	}
}
