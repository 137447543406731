.data-table {
	border-spacing: 24px;
	color: var(--body);
	overflow-x: auto;
}

.data-table thead tr:first-child {
	height: 56px;
	background-color: var(--divider20);
}

.data-table thead tr:first-child th {
	border: none;
}

.data-table tr td,
th {
	padding-inline: 12px !important;
	height: 72px;
}

.data-table thead tr:last-child th {
	border: none;
	border-bottom: 1px solid var(--divider40);
	height: 64px;
	vertical-align: middle;
}

.data-table thead tr:first-child th {
	color: var(--bodyLight);
	white-space: nowrap;
}

.data-table thead tr:last-child input {
	height: 40px;
	border: 1px solid var(--divider);
	border-radius: 8px;
	background: none;
	font-size: 16px !important;
}

.data-table tbody tr td {
	border: none;
	border-top: 1px solid var(--divider40);
	vertical-align: middle;
}

.data-table tbody td:first-child {
	padding-left: 24px !important;
}

.data-table tbody td:last-child {
	padding-right: 24px !important;
}

.data-table thead th:first-child {
	padding-left: 24px !important;
}

.data-table thead th:last-child {
	padding-right: 24px !important;
}

.table-header .row {
	padding-inline: 24px !important;
	margin-bottom: 24px !important;
}

.table-header-new .row {
	padding-inline: 24px !important;
	margin-bottom: 8px !important;
}

.table-header .row div:first-child input {
	height: 48px;
	border: 1px solid var(--divider);
	border-radius: 8px;
	background: none;
	font-size: 16px;
	max-width: 400px;
	width: 100%;
}

.table-header .row div:last-child select {
	appearance: none;
	height: 48px;
	border: 1px solid var(--divider);
	border-radius: 8px;
	background: none;
	font-size: 16px;
	padding-inline: 8px !important;
	width: 60px;
	background-image: url("data:image/svg+xml,%3Csvg%20width%3D%22800px%22%20height%3D%22800px%22%20viewBox%3D%220%200%2024%2024%22%20fill%3D%22none%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%3E%3Cpath%20fill-rule%3D%22evenodd%22%20clip-rule%3D%22evenodd%22%20d%3D%22M16.5303%208.96967C16.8232%209.26256%2016.8232%209.73744%2016.5303%2010.0303L12.5303%2014.0303C12.2374%2014.3232%2011.7626%2014.3232%2011.4697%2014.0303L7.46967%2010.0303C7.17678%209.73744%207.17678%209.26256%207.46967%208.96967C7.76256%208.67678%208.23744%208.67678%208.53033%208.96967L12%2012.4393L15.4697%208.96967C15.7626%208.67678%2016.2374%208.67678%2016.5303%208.96967Z%22%20fill%3D%22%23000000%22%2F%3E%3C%2Fsvg%3E");
	background-repeat: no-repeat;
	background-position: right 0.4rem top 50%;
	background-size: 24px;
}

.table-header .row div:last-child label {
	font-size: 16px;
	color: var(--bodyLight);
}

.table-header .pagination {
	margin-inline: 24px;
	padding-bottom: 24px;
}

.table-header .pagination .page-item a {
	border: 1px solid var(--divider40);
}

.table-header .pagination .page-item a:hover {
	background-color: var(--divider20);
}

.table-header .pagination .page-item:first-child a {
	border-radius: 8px 0 0 8px;
}

.table-header .pagination .page-item:last-child a {
	border-radius: 0 8px 8px 0;
}

.table-header .pagination li a {
	width: 48px;
	height: 48px;
	display: grid;
	place-content: center;
	color: var(--bodyLight);
}

.table-header .pagination li span {
	width: 48px;
	height: 48px;
	place-content: center;
	text-align: center;
	color: var(--bodyLight);
}

.table-header .pagination .page-item span {
	border: 1px solid var(--divider40);
}

.table-header .pagination .active a {
	background-color: var(--accent);
	color: white;
	font-weight: 500;
	border: none;
}

.table-header .pagination .active a:hover {
	background-color: var(--accent);
}

.column-button {
	padding: 8px;
	background-color: transparent;
	border: none;
	border-radius: 8px;
	color: var(--heading);
}

.column-button:hover {
	background-color: var(--divider20);
}
