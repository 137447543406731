// If you want to override variables do it here
@import "variables";

// Import CoreUI styles
@import "~@coreui/coreui/scss/coreui.scss";

// Some temp fixes
@import "fixes";

// If you want to add something do it here
@import "custom";

.table-row {
  background-color: #e2e3c5 !important;
}
.table-not-delivery {
  background-color: #e2dffd !important;
}
.table-delivery {
  background-color: #e1fcea !important;
}
.table-pickup-point {
  background-color: #fff8e6 !important;
}
.table-order-mobile {
	background-color:  #E9FAC8 !important;
}

.table-present {
  background-color: #e1fcea !important;
}
.table-not-present {
  background-color: #fcdddd !important;
}
.table-disabled {
 // set opacity to 0.5
 opacity: 0.9;
 //disable pointer events
//  pointer-events: none;
 background-color:  #e1fcea  !important;
 // gradient
 color:black !important;
}
.header-invoices {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}
.header-invoice-buttons {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}

.c-sidebar-nav-link:hover {
  //lighten the background color inherited from the parent
  background-color: inherit !important;
  //add some opacity to the background color
  background-color: rgba(0, 0, 0, 0.378) !important;
}
